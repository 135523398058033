import axios from "axios";

export async function handleContactUsForm(formData) {
  const url =
    "https://uequations-2532-fn.azurewebsites.net/api/rabbitmq-contactUs-newTask?code=rZqvauiojmYKqQi5hX6FJRF04yNhIdPC7fxehGKKWRQ1AzFuwiZ74Q==";

  const name = formData.get("name");
  const email = formData.get("email");
  const message = formData.get("message");

  await axios
    .post(url, { fn: name, e: email, m: message })
    .then((response) => {
      handleContactUsFormStatus(response.data.incoming.fn);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function handleContactUsFormStatus(name) {
  const msg =
    "Thank you. Message received, " +
    name +
    ". We will get back to you shortly.";
  const status = document.getElementById("contact-us-form-status");
  status.innerHTML = msg;
}

export async function handleSubscribeViaEmailForm(formData) {
  const url =
    "https://uequations-2532-fn.azurewebsites.net/api/rabbitmq-subscribeViaEmail-newTask?code=gcio6WQAjU70ZQxqcL_Z_HWzoXzwZkxwAWRQQCGFvU6hAzFunMOWOg==";
  const email = formData.get("email");
  await axios
    .post(url, { e: email })
    .then((response) => {
      handleSubscribeViaEmailFormStatus(response.data.incoming.e);
    })
    .catch((error) => {
      console.log(error);
    });
}

export function handleSubscribeViaEmailFormStatus(email) {
  const msg =
    "Thank you. Subscription request received. Alerts will be sent to " +
    email +
    ".";
  const status = document.getElementById("subscribe-via-email-form-status");
  status.innerHTML = msg;
}

const formElem = document.getElementById("contact-us-form");

if (formElem) {
  formElem.addEventListener("submit", (e) => {
    e.preventDefault();
    document.getElementById("contact-us-btn").disabled = true;
    let formData = new FormData(formElem);
    handleContactUsForm(formData);
  });
}

const subscribeViaEmailFormElem = document.getElementById(
  "subscribe-via-email-form"
);

if (subscribeViaEmailFormElem) {
  subscribeViaEmailFormElem.addEventListener("submit", (e) => {
    e.preventDefault();
    let subscribeViaEmailFormData = new FormData(subscribeViaEmailFormElem);
    handleSubscribeViaEmailForm(subscribeViaEmailFormData);
  });
}
